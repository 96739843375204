import React, { useEffect, useState } from "react";

function SecondaryBotTarget({ secondaryBotTarget }) {
  const [regenRate, setRegenRate] = useState(0)

  useEffect(() => {
    if(secondaryBotTarget.regen_per_second && secondaryBotTarget.planet.max_health){
      setRegenRate(360000*secondaryBotTarget.regen_per_second/secondaryBotTarget.planet.max_health)
    }
  }, [secondaryBotTarget])


  let planetImageClassName = "planet-image " + secondaryBotTarget.planet.enemy + " secondary";

  return (
    <>
      {secondaryBotTarget ? (
        <>
        <div className="planet-container Automaton">
          <div className="planet-container-top">
            <h2 className="primary-target">Secondary Automaton Target</h2>
          </div>
          <div className="planet-container-center">
            <div className="planet-container-left">
                <p>Active Players: {secondaryBotTarget.players}</p>
                <p>Regen Rate: {regenRate.toFixed(2)}%</p>
            </div>
            <div className={planetImageClassName}>
              <p className="planet-name secondary">{secondaryBotTarget.planet.name}</p>
            </div>
            <div className="planet-container-right">
              <p className="planet-owner">{secondaryBotTarget.planet.enemy}</p>
              <p className="planet-value">Voted: {secondaryBotTarget.planet.percentage_value.toFixed(1)}%</p>
            </div>
          </div>
        </div>
        </>
      ) : (
        "No planets found"
      )}
    </>
  );
}

export default SecondaryBotTarget;
