import React from "react";
import "./Vote.css";
import { Draggable } from "react-beautiful-dnd";

function Vote({ planet, index }) {
  return (
    <Draggable draggableId={planet.id.toString()} index={index}>
      {(provided) => (
        <div
          className="vote"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <p>{planet.planet.enemy}</p>
          <p>{planet.planet.name}</p>
        </div>
      )}
    </Draggable>
  );
}

export default Vote;
