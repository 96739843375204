import React, { useEffect, useState } from "react";
import "./PrimaryBotTarget.css";
function PrimaryBotTarget({ primaryBotTarget }) {
  const [regenRate, setRegenRate] = useState(0);

  useEffect(() => {
    if (
      primaryBotTarget.regen_per_second &&
      primaryBotTarget.planet.max_health
    ) {
      setRegenRate(
        (360000 * primaryBotTarget.regen_per_second) /
          primaryBotTarget.planet.max_health
      );
    }
  }, [primaryBotTarget]);

  let planetImageClassName =
    "planet-image " + primaryBotTarget.planet.enemy + " primary";
  return (
    <>
      {primaryBotTarget ? (
        <>
          <div className="planet-container Automaton">
            <div className="planet-container-top">
              <h1 className="primary-target">Primary Automaton Target</h1>
            </div>
            <div className="planet-container-center">
              <div className="planet-container-left">
                <p>Active Players: {primaryBotTarget.players}</p>
                <p>Regen Rate: {regenRate.toFixed(2)}%</p>
              </div>
              <div className={planetImageClassName}>
                <p className="planet-name primary">
                  {primaryBotTarget.planet.name}
                </p>
              </div>
              <div className="planet-container-right">
                <p className="planet-owner">{primaryBotTarget.planet.enemy}</p>
                <p className="planet-value">
                  Voted: {primaryBotTarget.planet.percentage_value.toFixed(1)}%
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        "No planets found"
      )}
    </>
  );
}

export default PrimaryBotTarget;
