import React from "react";
import SecondaryBugTarget from "../SecondaryBugTarget";
import PrimaryBugTarget from "../PrimaryBugTarget";

function BugContainer({ primaryBugTarget, secondaryBugTarget }) {
  return (
    <>
      {primaryBugTarget && (
        <PrimaryBugTarget primaryBugTarget={primaryBugTarget} />
      )}
      {secondaryBugTarget && (
        <SecondaryBugTarget secondaryBugTarget={secondaryBugTarget} />
      )}
    </>
  );
}

export default BugContainer;
