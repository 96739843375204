import React, { useState } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import "./LoginForm.css";

function LoginFormPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state.session.user);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  if (sessionUser) return <Redirect to="/" />;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await dispatch(login(name, password));
    if (data) {
      setErrors(data);
    }
  };

  const handleDemo = async (e) => {
    e.preventDefault();
    const data = await dispatch(login("demo@aa.io", "password"));
    if (data) {
      setErrors(data);
    }
  };

  return (
    <div className="login-page">
    <div className="form-container">
      <h1 className="h1">Log In</h1>
      <form onSubmit={handleSubmit}>
          {errors.length ?
        <p className="login-errors">Login information was incorrect</p> : null
        }
        <div className="span-holder">

        <span className="username">
          <label>Username<br></br></label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            />
        </span>
        <span className="password">
          <label>Password<br></br></label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            />
        </span>
        </div>
        <button type="submit">Log In</button>
      </form>
      <h3>Don't have an account?</h3>
        <button onClick={() => history.push("/signup")}>Sign Up</button>
    </div>
    </div>
  );
}

export default LoginFormPage;
