import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendBallot, updateBallot } from "../../store/planets";
import "./VoteContainer.css";
import VoteColumn from "../VoteColumn";
import { DragDropContext } from "react-beautiful-dnd";

function VoteContainer({ planets }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    planets: {},
    columns: {
      "column-1": {
        id: "column-1",
        title: "Available Planets",
        planetIds: [],
      },
      "column-2": {
        id: "column-2",
        title: "Priority Planets",
        planetIds: [],
      },
    },
  });
  const user = useSelector((state) => state.session.user);

  useEffect(() => {
    const updatedInitialData = {
      planets: {},
      columns: {
        "column-1": {
          id: "column-1",
          title: "Available Planets",
          planetIds: [],
        },
        "column-2": {
          id: "column-2",
          title: "Priority Planets",
          planetIds: [],
        },
      },
    };

    planets.forEach((planet, index) => {
      const planetId = `${planet.id}`;
      updatedInitialData.planets[planetId] = planet;
      updatedInitialData.columns["column-1"].planetIds.push(planetId);
    });

    setInitialData(updatedInitialData);
  }, [planets]);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }
  }, [user]);

  const maxVoteValue = 4;

  const handleVote = () => {
    const column2Planets = initialData.columns["column-2"].planetIds.map(
      (planetId) => initialData.planets[planetId]
    );

    // Calculate the number of votes to assign to each planet
    const numPlanets = column2Planets.length;
    const votesPerPlanet = Math.min(maxVoteValue, numPlanets);

    // Assign votes to each planet
    let votesToSend = {};
    for (let i = 0; i < numPlanets; i++) {
      const voteValue = Math.max(0, votesPerPlanet - i); // Ensure voteValue doesn't go negative
      votesToSend[column2Planets[i].id] = voteValue;
    }

    // Dispatch the vote action

    if (user.ballot_id) {
      dispatch(updateBallot({ json_vote: votesToSend }));
    } else {
      dispatch(sendBallot({ json_vote: votesToSend }));
    }

    history.push('/')
  };


  const handleOnDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startColumn = initialData.columns[source.droppableId];
    const finishColumn = initialData.columns[destination.droppableId];

    // If dragging within the same column
    if (startColumn === finishColumn) {
      const newPlanetIds = Array.from(startColumn.planetIds);
      newPlanetIds.splice(source.index, 1);
      newPlanetIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        planetIds: newPlanetIds,
      };

      const newColumns = {
        ...initialData.columns,
        [newColumn.id]: newColumn,
      };

      const newInitialData = {
        ...initialData,
        columns: newColumns,
      };

      setInitialData(newInitialData);
    } else { // If moving between columns
      const startPlanetIds = Array.from(startColumn.planetIds);
      startPlanetIds.splice(source.index, 1);
      const newStartColumn = {
        ...startColumn,
        planetIds: startPlanetIds,
      };

      const finishPlanetIds = Array.from(finishColumn.planetIds);
      finishPlanetIds.splice(destination.index, 0, draggableId);
      const newFinishColumn = {
        ...finishColumn,
        planetIds: finishPlanetIds,
      };

      const newColumns = {
        ...initialData.columns,
        [newStartColumn.id]: newStartColumn,
        [newFinishColumn.id]: newFinishColumn,
      };

      const newInitialData = {
        ...initialData,
        columns: newColumns,
      };

      setInitialData(newInitialData);
    }
  };

  return (
    <div className="voting-box">
      <h1>Voting</h1>
      <h3>Please place up to four targets that you think should be targeted in the Priority Planets box, in order of importance.</h3>
      <div className="vote-container">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {Object.keys(initialData.columns).length
            ? Object.values(initialData.columns).map((column) => {
                const planets = column.planetIds.map(
                  (planetId) => initialData.planets[planetId]
                );
                return (
                  <VoteColumn
                    key={column.id}
                    column={column}
                    planets={planets}
                  />
                );
              })
            : null}
        </DragDropContext>
      </div>
        <button onClick={handleVote}>Vote</button>
    </div>
  );
}

export default VoteContainer;
