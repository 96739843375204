const GET_PLANET_STATUS = "war/GET_PLANET_STATUS";
const GET_SINGLE_PLANET_STATUS = "war/GET_SINGLE_PLANET_STATUS";
const GET_PLANET = "planet/GET_PLANET";

const getStatus = (status) => ({
  type: GET_PLANET_STATUS,
  payload: status,
});

const getPlanet = (planets) => ({
  type: GET_PLANET,
  payload: planets,
});

const getPlanetStatus = (status) => ({
  type: GET_SINGLE_PLANET_STATUS,
  payload: status,
});

export const getPlanetsThunk = () => async (dispatch) => {
  try {
    const response = await fetch("/api/planets/");
    if (response.ok) {
      const data = await response.json();
      dispatch(getPlanet(data));
    } else {
      throw new Error("Failed to fetch planets");
    }
  } catch (error) {
    dispatch({ type: "FETCH_PLANET_STATUS_ERROR", payload: error.message });
  }
};

export const fetchPlanetStatus = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`/api/elixir/api/801/planets/${id}/status`);
      if (response.ok) {
        const data = await response.json();
        dispatch(getPlanetStatus(data));
        return true;
      } else {
        throw new Error("Failed to fetch Elixir status");
      }
    } catch (error) {
      dispatch({ type: "FETCH_ELIXIR_STATUS_ERROR", payload: error.message });
    }
  };
};

export const fetchWarStatus = () => {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/elixir/api/801/status"); // Assuming this hits the Elixir API proxy route
      if (response.ok) {
        const data = await response.json();
        dispatch(getStatus(data));
      } else {
        throw new Error("Failed to fetch Elixir status");
      }
    } catch (error) {
      dispatch({ type: "FETCH_ELIXIR_STATUS_ERROR", payload: error.message });
    }
  };
};

const initialState = {
  status: {
    campaigns: [],
  },
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLANET_STATUS:
      return { ...state, status: action.payload, error: null };
    case GET_SINGLE_PLANET_STATUS:
      const updatedSingleCampaigns = state.status.campaigns.map((campaign) => {
        if (campaign.planet.index === action.payload.planet.index) {
          return {
            ...campaign,
            planet: {
              ...campaign.planet,
              ...action.payload.planet,
            },
            players: action.payload.players,
            regen_per_second: action.payload.regen_per_second,
            liberation: action.payload.liberation,
          };
        }
        return campaign;
      });

      return {
        ...state,
        status: {
          ...state.status,
          campaigns: updatedSingleCampaigns,
        },
      };
    case GET_PLANET:
      const updatedCampaigns = state.status.campaigns.map((campaign) => {
        const matchingPlanet = action.payload.find(
          (payloadPlanet) => payloadPlanet.id === campaign.planet.index
        );

        if (matchingPlanet) {
          return {
            ...campaign,
            planet: {
              ...campaign.planet,
              ...matchingPlanet,
            },
          };
        } else {
          return {
            ...campaign,
            planet: {
              ...campaign.planet,
              id: campaign.planet.index,
              value: 0,
              enemy: campaign.planet.initial_owner,
            },
          };
        }
      });

      // Calculate total value across all planets
      const totalValue = updatedCampaigns.reduce(
        (acc, campaign) => acc + campaign.planet.value,
        0
      );

      // Add percentage_value to each planet
      const updatedCampaignsWithPercentage = updatedCampaigns.map(
        (campaign) => ({
          ...campaign,
          planet: {
            ...campaign.planet,
            percentage_value:
              totalValue === 0 ? 0 : (campaign.planet.value / totalValue) * 100,
          },
        })
      );

      return {
        ...state,
        status: {
          ...state.status,
          campaigns: updatedCampaignsWithPercentage,
        },
      };
    case "FETCH_PLANET_STATUS_ERROR":
      return { ...state, error: action.payload };
    case "FETCH_ELIXIR_STATUS_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
