import React, { useEffect, useState } from "react";

function PrimaryBugTarget({ primaryBugTarget }) {
  const [regenRate, setRegenRate] = useState(0)

  useEffect(() => {
    if(primaryBugTarget.regen_per_second && primaryBugTarget.planet.max_health){
      setRegenRate((360000*primaryBugTarget.regen_per_second)/primaryBugTarget.planet.max_health)
    }
  }, [primaryBugTarget])

  let planetImageClassName =
    "planet-image " + primaryBugTarget.planet.enemy + " primary";
  return (
    <>
      {primaryBugTarget ? (
        <>
          <div className="planet-container Terminids">
            <div className="planet-container-top">
              <h1 className="primary-target">Primary Terminid Target</h1>
            </div>
            <div className="planet-container-center">
              <div className="planet-container-left">
                <p>Active Players: {primaryBugTarget.players}</p>
                <p>Regen Rate: {regenRate.toFixed(2)}%</p>
              </div>
              <div className={planetImageClassName}>
                <p className="planet-name primary">
                  {primaryBugTarget.planet.name}
                </p>
              </div>
              <div className="planet-container-right">
                <p className="planet-owner">{primaryBugTarget.planet.enemy}</p>
                <p className="planet-value">
                  Voted: {primaryBugTarget.planet.percentage_value.toFixed(1)}%
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        "No planets found"
      )}
    </>
  );
}

export default PrimaryBugTarget;
