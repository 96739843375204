import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchPlanetStatus } from "../../store/war";
import { Container, Row, Col } from "react-grid-system";
import "./PrimaryTarget.css";

function PrimaryTarget({ primaryTarget }) {
  const dispatch = useDispatch();
  let mainDivClass = "planet-container " + primaryTarget.planet.enemy + " main";
  let planetImageClassName =
    "planet-image " + primaryTarget.planet.enemy + " main";
  const roundedLiberation = primaryTarget.liberation.toFixed(2);
  const [regenRate, setRegenRate] = useState(0);

  useEffect(() => {
    if (primaryTarget.regen_per_second && primaryTarget.planet.max_health) {
      setRegenRate(
        (360000 * primaryTarget.regen_per_second) /
          primaryTarget.planet.max_health
      );
    }
  }, [primaryTarget]);
  return (
    <>
      {primaryTarget ? (
        <div className="container planet primary">
          <div className="container planet row top">
            <div className="container planet column top-center">
              <h1>Primary Target</h1>
            </div>
          </div>
          <div className="container planet row center">
            <div className="container planet column center-left">
              <p>Enemy Type: {primaryTarget.planet.enemy}</p>
              <p>Active Players: {primaryTarget.players}</p>
            </div>
            <div className="container planet column center-center">
              <div className="planet-image">
                <h3>{primaryTarget.planet.name}</h3>
              </div>
            </div>
            <div className="container planet column center-right">
              <p>Regen Rate: {regenRate.toFixed(2)}%</p>
              <p>Voted: {primaryTarget.planet.percentage_value.toFixed(1)}%</p>
            </div>
          </div>
          <div className="container planet row bottom">
            <div className="container planet column bottom-center">
            <p>Captured: {roundedLiberation}%</p>
            </div>
          </div>
          {/* <div className="primary target-heading">
            <h1>Primary Target</h1>
          </div>
          <div className="planet-image primary">test</div>
          <div className="primary left stats">
            <p>stats 1</p>
            <p>stats 2</p>
          </div>
          <div className="primary right stats">
            <p>Active Players:</p>
            <p>Vote Result:</p>
            <p></p>
          </div>
          <div className="primary target-footer">
            <p>stats 5</p>
          </div> */}
        </div>
      ) : (
        "No planets found"
      )}
    </>
  );
}

export default PrimaryTarget;
