
export const sendBallot = (ballot) => async (dispatch) => {
  try {
      const response = await fetch("/api/ballots/", {
          method: "POST",
          headers: {
              "Content-Type": "application/json", // Specify the content type
          },
          body: JSON.stringify(ballot), // Convert the ballot object to JSON string
      });

      if (!response.ok) {
          throw new Error("Failed to send ballot.");
      }

      return "success"; // Return success if the request was successful
  } catch (error) {
      console.error("Error sending ballot:", error);
      return "error"; // Return error if there was an issue sending the ballot
  }
};

export const updateBallot = (ballot) => async (dispatch) => {
  try {
      const response = await fetch("/api/ballots/", {
          method: "PUT",
          headers: {
              "Content-Type": "application/json", // Specify the content type
          },
          body: JSON.stringify(ballot), // Convert the ballot object to JSON string
      });
      if (!response.ok) {
          throw new Error("Failed to send ballot.");
      }

      return "success"; // Return success if the request was successful
  } catch (error) {
      console.error("Error sending ballot:", error);
      return "error"; // Return error if there was an issue sending the ballot
  }
};
