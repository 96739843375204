import { Route, Switch } from "react-router-dom";
import LoginFormPage from "./components/LoginFormPage";
import SignupFormPage from "./components/SignupFormPage";
import TargetContainer from "./components/TargetContainer";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./store/session";
import { fetchWarStatus } from "./store/war";
import { useEffect, useState } from "react";
import { getPlanetsThunk } from "./store/war";
import VoteContainer from "./components/VoteContainer";
import { authenticate } from "./store/session";

function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValued, setIsValued] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.session.user);
  const campaigns = useSelector((state) => state.war.status.campaigns);
  const [planets, setPlanets] = useState([]);
  const handleLogin = () => {
    history.push("/login");
  };
  const handleSignup = () => {
    history.push("/signup");
  };
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const handleHome = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const handleGoVote = (e) => {
    e.preventDefault();
    history.push("/vote");
  };

  useEffect(() => {
    dispatch(fetchWarStatus())
      .then(() => dispatch(getPlanetsThunk()))
      .then(() => setIsValued(true));
  }, [dispatch]);

  useEffect(() => {
    setPlanets(campaigns.map((campaign) => campaign.planet));
  }, [campaigns]);

  useEffect(() => {
    dispatch(authenticate()).then(() => setIsLoaded(true));
  }, [dispatch]);

  return (
    isLoaded && planets.length && (
      <>
        <div className="navbar">
          {user ? (
            <>
              <div className="left-nav">
                <button onClick={handleHome}>Home Page</button>
              </div>
              <div className="right-nav">
                <button onClick={handleLogout}>Logout</button>
                <button onClick={handleGoVote}>Vote</button>
              </div>
            </>
          ) : (
            <>
              <div className="left-nav">
                <button onClick={handleHome}>Home Page</button>
              </div>
              <div className="right-nav">
                <button onClick={handleLogin}>Login</button>
                <button onClick={handleSignup}>Signup</button>
              </div>
            </>
          )}
        </div>
        <Switch>
          <Route path="/login">
            <LoginFormPage />
          </Route>
          <Route path="/signup">
            <SignupFormPage />
          </Route>
          <Route path="/vote">
            <VoteContainer planets={campaigns} />
          </Route>
          <Route path="/">
            <TargetContainer isValued={isValued} />
          </Route>
        </Switch>
      </>
    )
  );
}

export default App;
