import React from "react";
import SecondaryBotTarget from "../SecondaryBotTarget";
import PrimaryBotTarget from "../PrimaryBotTarget";

function BotContainer({primaryBotTarget, secondaryBotTarget}) {
    return(
      <>
      {primaryBotTarget && <PrimaryBotTarget primaryBotTarget={primaryBotTarget}/>}
      {secondaryBotTarget && <SecondaryBotTarget secondaryBotTarget={secondaryBotTarget}/>}
      </>
    )
}

export default BotContainer
