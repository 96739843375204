import React, { useEffect, useState } from "react";

function SecondaryBugTarget({ secondaryBugTarget }) {
  const [regenRate, setRegenRate] = useState(0)

  useEffect(() => {
    if(secondaryBugTarget.regen_per_second && secondaryBugTarget.planet.max_health){
      setRegenRate((360000*secondaryBugTarget.regen_per_second)/secondaryBugTarget.planet.max_health)
    }
  }, [secondaryBugTarget])

  let planetImageClassName =
    "planet-image " + secondaryBugTarget.planet.enemy + " secondary";
  return (
    <>
      {secondaryBugTarget ? (
        <>
          <div className="planet-container Terminids">
            <div className="planet-container-top">
              <h2 className="primary-target">Secondary Terminid Target</h2>
            </div>
            <div className="planet-container-center">
              <div className="planet-container-left">
                <p>Active Players: {secondaryBugTarget.players}</p>
                <p>Regen Rate: {regenRate.toFixed(2)}%</p>
              </div>
              <div className={planetImageClassName}>
                <p className="planet-name secondary">
                  {secondaryBugTarget.planet.name}
                </p>
              </div>
              <div className="planet-container-right">
                <p className="planet-owner">
                  {secondaryBugTarget.planet.enemy}
                </p>
                <p className="planet-value">
                  Voted: {secondaryBugTarget.planet.percentage_value.toFixed(1)}
                  %
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        "No planets found"
      )}
    </>
  );
}

export default SecondaryBugTarget;
