import React from "react";
import "./VoteColumn.css";
import Vote from "../Vote";
import { Droppable } from "react-beautiful-dnd";

function VoteColumn({ column, planets }) {
  return (
    <div className="vote-column-container">
      <h3 className="vote-column-title">{column.title}</h3>
      <Droppable droppableId={column.id}>
        {(provided) => (
          <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          >
            {planets.length
              ? planets.map((planet, index) => (
                  <Vote key={planet.id} planet={planet} index={index} />
                ))
              : null}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
export default VoteColumn;
