import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryTarget from "../PrimaryTarget";
import BotContainer from "../BotContainer";
import BugContainer from "../BugContainer";
import "./TargetContainer.css";
import { fetchPlanetStatus } from "../../store/war";
import { Container, Row, Col } from 'react-grid-system';

function TargetContainer({ isValued }) {
  const dispatch = useDispatch();
  const [planets, setPlanets] = useState([]);
  const [primaryTarget, setPrimaryTarget] = useState(null);
  const [primaryBugTarget, setPrimaryBugTarget] = useState(null);
  const [primaryBotTarget, setPrimaryBotTarget] = useState(null);
  const [secondaryBotTarget, setSecondaryBotTarget] = useState(null);
  const [secondaryBugTarget, setSecondaryBugTarget] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [highestPlanetIndexState, setHighestPlanetIndexState] = useState(null);
  const [highestBugIndexState, setHighestBugIndexState] = useState(null);
  const [highBugIndexState, setHighBugIndexState] = useState(null);
  const [highestBotIndexState, setHighestBotIndexState] = useState(null);
  const [highBotIndexState, setHighBotIndexState] = useState(null);
  const [targetsSet, setTargetsSet] = useState(false);
  const [totalValue, setTotalValue] = useState(0)
  const campaigns = useSelector((state) => state.war.status.campaigns);



  useEffect(() => {
    if (campaigns.length && !isLoaded && isValued) {
      setPlanets(campaigns.map((campaign) => campaign.planet));
    }
  }, [isValued, campaigns]);

  useEffect(() => {
    if (planets.length && !isLoaded) {
      let value = 0;

      let highestPlanetIndex = null;
      let highestBugIndex = null;
      let highBugIndex = null;
      let highestBotIndex = null;
      let highBotIndex = null;

      // Loop through the array of planets to find the planet with the highest value
      for (let i = 0; i < planets.length; i++) {
        if (planets[i].value > value) {
          value = planets[i].value;
          highestPlanetIndex = i;
        }

        if (planets[i].enemy === "Terminids") {
          if (
            highestBugIndex === null ||
            planets[i].value > planets[highestBugIndex].value
          ) {
            highBugIndex = highestBugIndex; // Store the previous highest bug index
            highestBugIndex = i; // Update the highest bug index
          } else if (
            highBugIndex === null ||
            planets[i].value > planets[highBugIndex].value
          ) {
            highBugIndex = i; // Update the second highest bug index
          }
        } else if (planets[i].enemy === "Automaton") {
          if (
            highestBotIndex === null ||
            planets[i].value > planets[highestBotIndex].value
          ) {
            highBotIndex = highestBotIndex; // Store the previous highest bot index
            highestBotIndex = i; // Update the highest bot index
          } else if (
            highBotIndex === null ||
            planets[i].value > planets[highBotIndex].value
          ) {
            highBotIndex = i; // Update the second highest bot index
          }
        }
      }

      setHighestPlanetIndexState(highestPlanetIndex);
      setHighestBugIndexState(highestBugIndex);
      setHighBugIndexState(highBugIndex);
      setHighestBotIndexState(highestBotIndex);
      setHighBotIndexState(highBotIndex);

      dispatch(fetchPlanetStatus(planets[highestPlanetIndex].id))
        .then(() => dispatch(fetchPlanetStatus(planets[highestBugIndex].id)))
        .then(() => dispatch(fetchPlanetStatus(planets[highestBotIndex].id)))
        .then(() => dispatch(fetchPlanetStatus(planets[highBugIndex].id)))
        .then(() => dispatch(fetchPlanetStatus(planets[highBotIndex].id)))
    }
  }, [planets.length]);

  useEffect(() => {
    setPrimaryTarget(campaigns[highestPlanetIndexState]);
    setPrimaryBugTarget(campaigns[highestBugIndexState]);
    setPrimaryBotTarget(campaigns[highestBotIndexState]);
    setSecondaryBotTarget(campaigns[highBotIndexState]);
    setSecondaryBugTarget(campaigns[highBugIndexState]);
    setTargetsSet(true);
  }, [campaigns]);

  return (
    targetsSet && (
      <div className="target-container" fluid>
          <div>
            <BotContainer
              primaryBotTarget={primaryBotTarget}
              secondaryBotTarget={secondaryBotTarget}
            />
          </div>
          {primaryTarget && (
            <div>
              <PrimaryTarget primaryTarget={primaryTarget} />
            </div>
          )}
          <div>
            <BugContainer
              primaryBugTarget={primaryBugTarget}
              secondaryBugTarget={secondaryBugTarget}
            />
          </div>
      </div>
    )
  );
}

export default TargetContainer;
